import React,{useState} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"


const HeroBanner = ({
  Heading,
  subHeading,
  postSubHeading=null,
  spanHeading="",
  Paragraph,
  btnText,
  bgImg,
  bgVideo=false,
  btn=false,
  btnRef="/",
  socialLinks=false,
  serviceForm=false,
  bgEffect=false,
  respBgImg=null,
  isCheveron=false,
  heightAuto=false,
  slideState=null,
  removeNavLinks=false
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 796);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 796);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); 

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Contact page Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          // setEmail("");
          navigate("/thank-you");
          setLoading(false);
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };
  return (
    <>
    <div
      className={`common_hero_section ${bgVideo && "bg-video"} ${heightAuto && "h-cus-auto"} mb-5`}
      style={{ backgroundImage: `url(${bgVideo ? bgVideo : bgImg})`,height:`${serviceForm?"auto":"40rem"}`}}
      
    >
      <div className={`${bgEffect && "bg-effect"} h-100`}>
       { bgVideo && <video autoPlay loop muted className="bg-video-mp">
        <source src="./images/home_banner_hero.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>} 

      <div className="hero-container h-100  px-0"
      
      >
     <div className="wrapper d-flex flex-column container">

      <Header 
        slideState={slideState}
        removeNavLinks={removeNavLinks}
      />
      <div 
        className=" d-flex align-items-center py-5 my-auto justify-content-center"
        >
        <div className="row gy-2 w-100">
          <div
            className={
              serviceForm
                ? `col-lg-6 mt-5 ${!socialLinks && "d-flex align-items-center"}`
                : "col-12 hero_content_wrapper px-3 w-100"
            }
          >
            <div className={`hero-text-content w-100 ${serviceForm?"text-start":"text-center"}`}>
            <h5 className="hero-sub-heading">{subHeading}</h5>
            <h1 className="hero-section-heading my-3" style={serviceForm ? {fontSize:"38px"}:{}}>
              {Heading}
              <span className="heading-color-change">{spanHeading}</span>
            </h1>

           { postSubHeading && <h5 className="hero-sub-heading" style={{color:"#39B4E4",fontWeight:"600"}}>{postSubHeading}</h5>}
            <p className="body-paragraph mx-auto">{Paragraph}</p>
            {btn && <button className="genral-btn mt-4" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
            {isCheveron && <img src="/images/icons/chevron.svg" alt="chevron"/>}
            {socialLinks && (
              <div className="social-links py-2">
                <div className="first-row d-flex flex-column flex-xl-row gap-2">
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-phone.png" alt="phone"/>
                    {/* <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>+1 647 4794288</p> */}
                    <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white",userSelect:"all"}}>(647) 479 4288</p>
                  </div>
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-mail.png" alt="social"/>
                    <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white",userSelect:"all"}}>info@hqns.ca</p>
                  </div>
                </div>
                
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-location.png" alt="social-location"/>
                  {/* <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>2601 Matheson Blvd East | Suite 206 | Mississauga, ON | L4W 5A8</p> */}
                  <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>5800 Ambler Dr, Mississauga, ON L4W 4J4</p>
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-time.png" alt="social-time"/>
                  <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>Monday to Friday (7:30 AM - 7:30 PM)</p>
                </div>
              </div>
            )}
          </div>
          </div>
          {serviceForm && (
            <div className="col-lg-6 mt-5 pb-5 mx-auto">
              <form className="service-form d-flex flex-column px-5 py-5" onSubmit={formSubmitHandler}>
                <h3 className="body-heading" style={{ color: "white",fontSize:"35px" }}>
                  Let’s get in touch!
                </h3>
                <p
                  className="body-paragraph text-start"
                  style={{ color: "white",fontSize:"20px" }}
                >
                  Your Gateway to Tailored Solutions
                </p>
                <input 
                   type="text" 
                   value={name}
                   maxLength="36"
                   required
                   onChange={handleNameChange}
                  placeholder="Your Full Name" 
                />
                <input 
                   type="email" 
                   value={email}
                   required
                   maxLength="66"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />
                <input 
                  type="tel" 
                  value={phone}
                  required
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                <input 
                  type="text" 
                  value={message}
                  required
                  maxLength="350"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                />
                <button className="service-from-btn  genral-btn px-5" type="submit" style={{fontWeight:"600"}}>Submit
                {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
      </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default HeroBanner; 